import React from "react"
import Layout from "../components/layout"

import { Helmet } from "react-helmet"

import { Container, Row, Col } from "react-bootstrap"

const Contact = () => {
  return (
    <>
      <Helmet bodyAttributes={{ class: "info short" }} />
      <Layout>
        <Container className="info-contain">
          <Row>
            <Col lg={12}>
              <h1 className="page-title">Contact</h1>
            </Col>
            <Col lg={12} className="contact-links">
              <p>Links:</p>
              <ul>
                <li>
                  <a href="mailto:elena@elenaguacimara.com">
                    hello@elenaguacimara.com
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/in/elenaguacimara/?originalSubdomain=es">
                    LinkedIn
                  </a>
                </li>
                <li>
                  <a href="https://scholar.google.es/citations?user=1wjClfcAAAAJ&hl=us">
                    Google Scholar
                  </a>
                </li>
                <li>
                  <a href="https://publons.com/researcher/3881097/elena-g-gonzalez/">
                    Web of Science Researcher ID
                  </a>
                </li>
                <li>
                  <a href="https://orcid.org/0000-0002-4614-3889">ORCID</a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Contact
